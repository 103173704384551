@charset "utf-8";
/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/

/* Animations for front page */
@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  80% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
li.li-animate {
  animation: 0.5s ease-out 0s 1 fadein;
}


/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: #000; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite a, { color: #fff; }
body.video cite a:visited, { color: #fff; }
body.video cite { color: #fff; }



/* Navigation
------------------------------------------------------------------- */
/* Not large Navigation */
a.parent-link {
  margin-left: -10px;
  font-weight: bold !important;
}

li.back {
  background-color: $primary-color !important;
  h5 {
     a {
        margin-left: -10px !important;
        color: #fff !important;
     }
  }
}

img.site-logo {
 max-height: $topbar-height*0.8; 
}

/* Search
------------------------------------------------------------------- */

.no-js form#search {
  display: none;
}
/* Index page
------------------------------------------------------------------- */
div.indexentry {
  padding-bottom: 2em;
}

/* Masthead
------------------------------------------------------------------- */

#masthead {
    background-color: $body-bg-content;
}
#masthead-no-image-header {
    background-color: $body-bg-content;
}
#masthead-with-text {
    text-align: center;
    font-size: rem-calc(54);
    font-family: $header-font-family;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 3px rgba(0,0,0,.4);
}
#masthead-no-image-header {
    height: 175px;
}
#masthead-no-image-header #logo img {
    margin-top: 100px;
}

/* Masthead › small-only
------------------------------------------------------------------- */

@media #{$small-only} {
    #logo img {
        display: none;
    }
    #masthead {
        height: 200px;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        height: 220px;
        padding: 30px 0;
        font-size: rem-calc(36);
    }
    #masthead-no-image-header {
        display: none;
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@media #{$medium-only} {
    #logo img {
        margin-top: 140px;
	display: none;
    }
    #masthead {
        height: 240px;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 60px 0;
        height: 300px;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@media #{$large-only} {
    #logo img {
        margin-top: 100px;
    }
    #masthead {
        height: 260px;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        height: 330px;
        padding: 60px 0;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@media #{$xlarge-up} {
    #logo img {
        margin-top: 110px;
    }
    #masthead {
        height: 300px;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: 95px 0;
        height: 400px;
    }
}


#title-image-small {
    height: 240px;
}
#title-image-large {
    height: 520px;
}
#title-image-index-small {
    height: 120px;
}
#title-image-index-large {
    height: 260px;
}



/* Breadcrumb
------------------------------------------------------------------- */

#breadcrumb {
    background: scale-color($grey-1, $lightness: 55%);
    border-top: 1px solid scale-color($grey-1, $lightness: 45%);
    border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
}
.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta, #page-meta a {
    color: $grey-5;
}

#page-meta .button {
    background: $grey-5;
    border: 0;
}
#page-meta .button {
    color: #fff;
}
#page-meta .button:hover {
    background: $primary-color;
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-1, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 160px 0 10px 0;
}
#up-to-top a {
    font-size: 24px;
    padding: 5px;
    border-radius: 3px;
}
#up-to-top a:hover {
    background: $grey-2;
}

/* News footer
------------------------------------------------------------------- */

img.newsmain {
  margin-top: -1.563em;
}

/* Footer
------------------------------------------------------------------- */

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 300;
}

#footer {
    background: $footer-bg;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
        text-shadow: none;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
    }



/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-bg;
    color: $subfooter-color;
    padding-top: 5px;
}

#subfooter-left ul.inline-list {
    float: left;
}

.credits a {
    color: $subfooter-link-color;
    border: 0;
    text-transform: uppercase;
    &:hover {
        color: #fff;
  }
}

.social-icons {
    margin-bottom: 10px !important;

// Beware of SCSS-Syntax here
  li {
    padding: 0 0 20px 0;
  }
  a {
    font-size: rem-calc(23);
    display: block;
    width: 36px;
    border-radius: 50%;
    color: $subfooter-bg;
    background: $subfooter-color;
    text-align: center;
    &:hover {
      background: $subfooter-bg;
      color: #fff;
    }
  }
}



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 60px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 60px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }
